var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4 mt-2"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('tables.investmentsTable'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"loading":_vm.isLoading,"items-per-page":_vm.pageSize,"footer-props":{
      itemsPerPageOptions: [10, 20, 50]
    },"item-key":"ESGRatingISIN","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.isInvestible",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{staticClass:"ma-1",attrs:{"size":"25","color":item.isInvestible ? 'green' : 'red'}})]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-switch',{staticClass:"mt-2",attrs:{"label":"Einzelne Erweiterung"},model:{value:(_vm.singleExpand),callback:function ($$v) {_vm.singleExpand=$$v},expression:"singleExpand"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{staticStyle:{"white-space":"pre-wrap"},attrs:{"colspan":headers.length}},[_c('p',[_c('strong',[_vm._v("Details")])]),_c('div',[_vm._v(_vm._s(item.ESGRatingRatedEntity)+": "+_vm._s(item.message))]),_c('div',[_c('p',{staticStyle:{"text-align":"end"}},[_c('v-btn',{staticClass:"pdf",attrs:{"color":"red","text":"","right":"","height":"auto","x-small":""},on:{"click":function($event){return _vm.generatePdf(item.ESGRatingISIN)}}},[_c('v-icon',[_vm._v("mdi-file-pdf")]),_vm._v(" .PDF ")],1)],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }