<template>
  <v-card class="pa-4 mt-2">
    <v-card-title>
      {{ $t('tables.investmentsTable') }}
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :loading="isLoading"
      :items-per-page="pageSize"
      :footer-props="{
        itemsPerPageOptions: [10, 20, 50]
      }"
      item-key="ESGRatingISIN"
      show-expand
      class="elevation-1"
    >
      <template v-slot:item.isInvestible="{ item }">
        <v-avatar class="ma-1" size="25" :color="item.isInvestible ? 'green' : 'red'">&nbsp; </v-avatar>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-switch v-model="singleExpand" label="Einzelne Erweiterung" class="mt-2"></v-switch>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="white-space: pre-wrap">
          <p><strong>Details</strong></p>
          <div>{{ item.ESGRatingRatedEntity }}: {{ item.message }}</div>
          <div>
            <p style="text-align: end">
              <v-btn class="pdf" color="red" text right height="auto" x-small @click="generatePdf(item.ESGRatingISIN)">
                <v-icon>mdi-file-pdf</v-icon>
                .PDF
              </v-btn>
            </p>
          </div>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { auth, db } from '@/plugins/firebase';
import { collection, getDocs } from 'firebase/firestore';
import axios from 'axios';

export default {
  data() {
    return {
      firestoreCollection: 'investments-' + process.env.VUE_APP_ENV,
      search: '',
      headers: [
        {
          text: 'Dt. Emittentennummer',
          align: 'start',
          sortable: false,
          value: 'EKN'
        },
        { text: 'ISIN', value: 'ESGRatingISIN' },
        { text: 'Firma/Land', value: 'ESGRatingRatedEntity' },
        { text: 'Bereich', value: 'Industry' },
        { text: 'Land', value: 'Country' },
        { text: 'Investierbar', value: 'isInvestible' }
      ],
      expanded: [],
      investments: [],
      itemsPerPage: [],
      items: [],
      pageSize: 10,
      currentPage: 1,
      isLoading: true,
      allItems: [],
      singleExpand: false
    };
  },

  mounted: function () {
    this.getAllItems();
  },
  computed: {
    filteredItems: function () {
      if (this.search.length === 0) {
        return this.allItems;
      }
      const search = this.search.toLowerCase();
      return this.allItems.filter((item) => {
        if (item.EKN && `${item.EKN}`.toLowerCase().match(search)) {
          return true;
        }
        if (item.ESGRatingISIN && item.ESGRatingISIN.toLowerCase().match(search)) {
          return true;
        }
        if (item.ESGRatingRatedEntity && item.ESGRatingRatedEntity.toLowerCase().match(search)) {
          return true;
        }
        if (item.Industry && item.Industry.toLowerCase().match(search)) {
          return true;
        }
        if (item.Country && item.Country.toLowerCase().match(search)) {
          return true;
        }
        return item.message && item.message.toLowerCase().match(search);
      });
    }
  },
  methods: {
    async getAllItems() {
      this.isLoading = true;
      try {
        const querySnapshot = await getDocs(collection(db, this.firestoreCollection));
        this.allItems = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...{
              ESGRatingISIN: data.isin,
              EKN: data.ekn,
              ESGRatingRatedEntity: data.name,
              Industry: data.industry,
              Country: data.country,
              isInvestible: data.isInvestible,
              message: data.message
            }
          };
        });
      } finally {
        this.isLoading = false;
      }
    },
    async generatePdf(isinOfItem) {
      const url = process.env.VUE_APP_PDF_GENERATOR_URL;
      const token = await auth.currentUser.getIdToken();
      const body = { isin: isinOfItem };
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const responseType = 'arraybuffer';
      await axios
        .post(url, body, { headers, responseType })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = response.headers['x-details-filename'];
          link.click();
        })
        .catch((error) => {
          console.error('Error occurs when downloading PDF: ', error);
        });
    }
  }
};
</script>

<style scoped>
p {
  color: black;
  margin-top: 2em;
}
</style>
