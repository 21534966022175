<template>
  <div class="tablecompanies">
    <TableCompanies />
  </div>
</template>

<script>
import TableCompanies from '../components/TableCompanies.vue';

export default {
  name: 'CompanyTable',
  components: {
    TableCompanies
  },
  data: () => ({})
};
</script>

<style>
div.tablecompanies {
  width: 80em;
}
</style>
